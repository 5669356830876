<template>
  <div class="overflow-hidden">
    <apexchart type="donut" height="350" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";
import { COLORSTONE1 } from "../../../../helpers/variables";

export default {
  name: 'TotalpublishTypeChartComponent',
  data() {
    return {
      series: [], // Store numerical values for the chart
      options: {
        chart: {
          fontFamily: "Manrope, sans-serif",
          type: "donut",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: COLORSTONE1,

        labels: [], // Store category labels

        dataLabels: { enabled: false },

        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: { fontSize: "2rem" },
                value: {
                  fontSize: "16px",
                  formatter(val) {
                    return ` ${val} บทความ`;
                  },
                },
                total: {
                  show: true,
                  fontSize: "16px",
                  label: "รวมบทความทั้งหมด",
                  formatter: function (w) {

                    if (!w.globals.seriesTotals || !Array.isArray(w.globals.seriesTotals) || w.globals.seriesTotals.length === 0) {
                      return `0 บทความ`;
                    }

                    let total = w.globals.seriesTotals.reduce((acc, val) => acc + (Number(val) || 0), 0);
                    return `${total} บทความ`;
                  },
                },
              },
            },
          },
        },

        legend: {
          itemMargin: { horizontal: 24, vertical: 0 },
          horizontalAlign: "center",
          position: "bottom",
          fontSize: "14px",
          markers: { radius: 12 },
        },
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  methods: {
    async getTotalPublishType() {
      try {
        let response = await dashboardsService.getPublishTypeChart();

        if (response.data) {
          this.series = Array.isArray(response.data.values) ? response.data.values.map(Number) : [];

          // ✅ Force update the labels to avoid default ApexCharts labels
          this.options = {
            ...this.options, // Keep existing options
            labels: Array.isArray(response.data.labels) ? response.data.labels : []
          };
        } else {
          this.series = [];
          this.options = {
            ...this.options,
            labels: []
          };
        }

      } catch (error) {
        this.series = [];
        this.options = {
          ...this.options,
          labels: []
        };
      }
    }
  },

  async mounted() {
    await this.getTotalPublishType();
  },
};
</script>
